<template>
    <div v-if="totalAmount > 0 && showSidebar === true" class="h-100">
        <div class="cart-sidebar__header" v-if="disableFreeShippingBar === false">
            <SidebarProgressBar
                :free-shipping-amount="amountTillFreeShipping"
                :progress-bar-percentage="progressBarPercentage"
                :is-free-shipping="isFreeShipping"
                :free-shipping-reached-text="freeShippingReachedText"
                :amount-to-free-shipping-text-part1="amountToFreeShippingTextPart1"
                :amount-to-free-shipping-text-part2="amountToFreeShippingTextPart2"
                :amount-to-free-shipping-text-part3="amountToFreeShippingTextPart3">
            </SidebarProgressBar>
        </div>

        <div class="cart-sidebar__body">
            <CartItem
                v-for="(cartItemProduct) in products"
                :cart-item-product="cartItemProduct"
                :key="cartItemProduct.productId"
                :currency="currency"
                :currencyFormat="currencyFormat"

                @remove="removeFromCartItems(cartItemProduct.articleNumber)"
                @increment-amount="incrementTotalPrice"
                @decrement-amount="decrementTotalPrice"
                @calc-total-amount="calcTotalAmount">
            </CartItem>
        </div>

        <SidebarFooter
            :total-amount="formatPrice(totalAmount)"
            :discount="discount"
            :shipping-costs="this.shippingCosts"
            :checkout-link="this.checkoutLink"
            :paypal-link="this.paypalLink">
        </SidebarFooter>
    </div>

    <div v-else class="h-100">
        <div class="cart-sidebar__body cart-sidebar__body--is-empty">
            <h3>{{ cartEmptyText }}</h3>
            <a :href="productOverviewLink" class="btn btn-primary btn-icon">
                {{ productOverviewLinkText }}
                <span class="btn-icon__icon icon icon-arrow-right"></span>
            </a>
        </div>
    </div>
</template>

<script>
import SidebarProgressBar from "../components/cart/SidebarProgressBar.vue";
import CartItem from "../components/cart/CartItem.vue";
import SidebarFooter from "../components/cart/SidebarFooter.vue";
import {getCartItemsConfig} from "../../utils/config-helper";
import {throwAlertMessage} from "../../utils/utils";
import axios from "axios";
import { assert, object, number, string, array, boolean, integer, optional } from "superstruct";
import {translate} from '@elements/translations';
import {initInScope} from '@elements/init-modules-in-scope';

const initialProducts = object({
    successText: string(),
    currency: string(),
    currencyFormat: string(),
    freeShippingAmount: number(),
    discount: optional(number()),
    shippingCosts: optional(number()),
    totalAmount: number(),
    disableFreeShippingBar: optional(boolean()),
    checkoutLink: string(),
    paypalLink: optional(string()),
    cartEmptyText: string(),
    productOverviewLink: string(),
    productOverviewLinkText: string(),
    freeShippingReachedText: string(),
    amountToFreeShippingTextPart1: string(),
    amountToFreeShippingTextPart2: string(),
    amountToFreeShippingTextPart3: string(),
    products: optional(array(object({
        productId : number(),
        articleNumber : string(),
        name : string(),
        img : string(),
        imgDiscountBadge : string(),
        amount : integer(),
        maxAmount: integer(),
        singlePrice : number(),
        defaultPrice : number(),
        totalPrice : number(),
        taxInfo : string(),
        info : string(),
        infoState : string(),
        discountText : optional(string()),
        discountAmount: number(),
        size : optional(array(object({
            name: string(),
            value: string(),
            isSelected: boolean()
        }))),
    }))),
});

export default {
    components: {
        CartItem,
        SidebarProgressBar,
        SidebarFooter
    },
    props: {
        showSidebar: {
            type: Boolean,
            default: false
        },
        test:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            totalProducts: 0,
            products: [],
            showModal: false,
            successText: false,
            currency: '',
            currencyFormat: '',
            totalAmount: 0,
            discount: 0,
            shippingCosts: 0,
            formattedTotalAmount: 0,
            isFreeShipping: false,
            freeShippingAmount: 0,
            checkoutLink: '',
            paypalLink: '',
            cartEmptyText: '',
            productOverviewText: '',
            productOverviewLink: '',
            productOverviewLinkText: '',
            freeShippingReachedText: '',
            amountToFreeShippingTextPart1: '',
            amountToFreeShippingTextPart2: '',
            amountToFreeShippingTextPart3: '',
            disableFreeShippingBar: false,
        }
    },
    computed: {
        amountTillFreeShipping() {
            if(this.totalAmount <= this.freeShippingAmount) {
                let price = this.freeShippingAmount - this.totalAmount;
                this.isFreeShipping = false;
                return this.formatPrice(price);
            } else {
                this.isFreeShipping = true;
                return null;
            }
        },
        progressBarPercentage() {
            if(this.totalAmount <= this.freeShippingAmount) {
                return this.totalAmount / this.freeShippingAmount * 100;
            } else {
                return 100;
            }
        },
    },
    mounted(){
        setTimeout(() => {
            this.calcTotalAmount();
        }, 100);
    },
    watch: {
        showSidebar(value) {
            console.log('value',value);
            if (value === true) {
                this.getInitialCartItems();
            }
        },
    },
    methods:{
        getLang(){
            const _config = window._config || {};
            let lang = _config.langShort;
            return lang;
        },
        formatPrice(price){
            return new Intl.NumberFormat(this.getLang(), { style: 'currency', currency: this.currency ? this.currency : 'EUR' }).format(price);
        },
        calcTotalAmount(){
            this.totalAmount = 0;
            this.totalAmount = this.totalAmount + this.shippingCosts - this.discount;
            this.products.forEach(product => {
                this.totalAmount += product.totalPrice;
            });
        },
        incrementTotalPrice(price) {
            this.totalAmount += price;
        },
        decrementTotalPrice(price) {
            this.totalAmount -= price;
        },
        changeSuccessText(input){
            let url = getCartItemsConfig().changeMailSuccessText;
            axios.get(url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                params: {
                    email: input,
                }
            });
            this.showModal = false;
            this.successText = true;
            //this.products=[];
        },
        getInitialCartItems(){
            let url = getCartItemsConfig().initialCartItems;
            axios
                .get(url)
                .then((response) => response.data)
                .then(response => {
                    try{
                        console.log(response.data);
                        assert(response.data, initialProducts);
                    }catch (error){
                        console.warn(error);
                        let errorText = `<p><strong>initial cart items has wrong data types</strong></p>`;
                        throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                    }
                    this.currency = response.data.currency;
                    this.currencyFormat = response.data.currencyFormat;
                    this.products = response.data.products ? response.data.products : [];
                    this.freeShippingAmount = response.data.freeShippingAmount;
                    this.discount = response.data.discount;
                    this.shippingCosts = response.data.shippingCosts ? response.data.shippingCosts : 0;
                    this.checkoutLink = response.data.checkoutLink;
                    this.paypalLink = response.data.paypalLink ? response.data.paypalLink : null;
                    this.totalAmount = response.data.totalAmount;
                    this.cartEmptyText = response.data.cartEmptyText;
                    this.productOverviewLink = response.data.productOverviewLink;
                    this.productOverviewLinkText = response.data.productOverviewLinkText;
                    this.freeShippingReachedText = response.data.freeShippingReachedText;
                    this.amountToFreeShippingTextPart1 = response.data.amountToFreeShippingTextPart1;
                    this.amountToFreeShippingTextPart2 = response.data.amountToFreeShippingTextPart2;
                    this.amountToFreeShippingTextPart3 = response.data.amountToFreeShippingTextPart3;
                    this.disableFreeShippingBar = response.data.disableFreeShippingBar ? response.data.disableFreeShippingBar : false;
                    this.discountText = response.data.discountText ? response.data.discountText : null;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        removeFromCartItems(product){
            let url = getCartItemsConfig().deleteProductFromCartItems;
            axios.get(url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                params: {
                    articleNumber: product,
                }
            });
            let itemToRemove = this.products.find(item => item.articleNumber === product);
            let index = this.products.indexOf(itemToRemove);
            this.products.splice(index, 1);
            this.calcTotalAmount();
        },
        translate
    },
}
</script>

<style scoped type="scss">

</style>
