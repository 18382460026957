<template>
    <div class="cart-sidebar__footer bg-light">
        <div class="cart-sidebar__prices-wrapper">
            <div v-if="discount > 0" class="cart-sidebar__price-item">
                <span>Rabatte</span>
                <span>-{{ formatPrice(discount) }}</span>
            </div>
            <div class="cart-sidebar__price-item">
                <span>Versandkosten nach AT</span>
                <span v-if="shippingCosts > 0">{{ formatPrice(shippingCosts) }}</span>
                <span v-else>{{ translate('cart.no-shipping-costs') }}</span>
            </div>
            <div class="cart-sidebar__price-item">
                <span class="cart-sidebar__total">Gesamtsumme
                    <span class="cart-sidebar__taxes">inkl. MwSt.</span>
                </span>
                <span class="cart-sidebar__total-price strong">{{ totalAmount }}</span>
            </div>
        </div>

        <div class="cart-sidebar__footer-buttons row">
            <div class="col-6">
                <a :href="checkoutLink" class="btn btn-primary w-100">
                    Weiter zur Kasse
                </a>
            </div>
            <div class="col-6">
                <a :href="paypalLink" class="btn btn-info btn-img position-relative w-100" v-if="paypalLink">
                    <img class="btn-img__img js-lazy-img" src="/static/img/paypal.svg" alt="paypal">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {translate} from '@elements/translations';
export default {
    props: {
        totalAmount: "",
        discount: "",
        shippingCosts: "",
        checkoutLink: "",
        paypalLink: ""
    },
    methods:{
        getLang(){
            const _config = window._config || {};
            let lang = _config.langShort;
            return lang;
        },
        formatPrice(price){
            return new Intl.NumberFormat(this.getLang(), { style: 'currency', currency: this.currency ? this.currency : 'EUR' }).format(price);
        },
        translate
    }
}
</script>